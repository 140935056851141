.desktopContactContainer {
  width: 100%;
  padding: 50px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #262626;
  color: white;
  margin-top: 100px;
  .desktopContactMethods {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 1060px;
    margin-bottom: 40px;
    div {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      img {
        width: 30px;
        margin-right: 10px;
        filter: invert(1);
      }
    }
  }
}
