.aboutBoxContainer {
  border-radius: 10px;
  width: 150px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 30px #295cd434;
  margin: 0;
  p {
    margin: 0;
    padding: 0;
  }
}

@media screen and (max-width: 374px) {
  .aboutBoxContainer {
    font-size: 14px;
    max-width: 40vw;
    max-height: 40vw;
  }
}
