.tabletPregoContainer {
  width: 623px;
  color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-top: 15px;
  position: relative;
  .tabletPregoText {
    width: 100%;
    h1 {
      font-size: 44px;
      font-family: "FuturaHeavy";
      line-height: 0.5;
    }
  }
  .tabletPrego {
    display: grid;
    grid-template-columns: 307px 307px;
    column-gap: 20px;
    width: 100%;
    div {
      width: 100%;
    }
  }
}
