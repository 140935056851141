.servicesModalContainer {
  position: fixed;
  left: 0;
  top: 61px;
  width: 100vw;
  height: calc(100vh - 80px);
  padding-bottom: 20px;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.4);
  backdrop-filter: blur(20px);
  .servicesModal {
    width: 325px;
    height: 620px;
    background-color: white;
    box-shadow: 0 0 30px #295cd434;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .servicesTitle {
      width: 100%;
      padding: 10px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: 23px;
        font-family: "FuturaHeavy";
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        margin: 0;
        margin-left: 20px;
        text-align: left;
      }
      p {
        font-size: 18px;
        margin-right: 20px;
      }
    }
    .servicesContent {
      overflow-y: scroll;
      width: 285px;
      .servicesImg {
        width: 285px;
        height: 200px;
        box-shadow: 0 0 30px #295cd434;
        border-radius: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        z-index: 100;
        position: relative;
      }
      .servicesPregoContent {
        z-index: 99;
        .servicesModalButton {
          width: 285px;
          margin: 20px 0;
          border: none;
          box-shadow: 0 0 30px #295cd434;
        }
      }
      .servicesRemovableContent {
        .servicesModalButton {
          width: 285px;
          margin: 20px 0;
          border: none;
          box-shadow: 0 0 30px #295cd434;
        }
        .servicesModalSeparator {
          position: relative;
          width: 285px;
          display: flex;
          justify-content: center;
          p {
            background-color: white;
            z-index: 100;
            position: relative;
            width: calc(100% - 120px);
            text-align: center;
          }
          div {
            z-index: 99;
            left: 0;
            right: 0;
            bottom: 0;
            top: 0;
            margin: auto;
            width: 80%;
            height: 0;
            border: 1px solid grey;
            position: absolute;
          }
        }
        .servicesParagraph {
          text-align: left;
          margin: 20px 10px;
          font-size: 18px;
        }
      }
    }
  }
}

@media screen and (min-width: 541px) {
  .servicesModalContainer {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    padding-bottom: 20px;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(255, 255, 255, 0.4);
    backdrop-filter: blur(20px);
    .servicesModal {
      width: 634px;
      height: 620px;
      background-color: white;
      box-shadow: 0 0 30px #295cd434;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;
      .servicesTitle {
        width: 100%;
        padding: 20px;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h1 {
          font-size: 23px;
          font-family: "FuturaHeavy";
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80%;
          margin: 0;
          margin-left: 60px;
          text-align: left;
        }
        p {
          font-size: 18px;
          margin-right: 60px;
          cursor: pointer;
        }
      }
      .servicesContent {
        overflow-y: scroll;
        width: 514px;
        padding-bottom: 40px;
        .servicesImg {
          width: 514px;
          height: 250px;
          box-shadow: 0 0 30px #295cd434;
          border-radius: 10px;
        }
        .servicesPregoContent {
          z-index: 99;
          .servicesModalButton {
            width: 514px;
            height: 70px;
            border-radius: 10px;
            margin: 20px 0;
            border: none;
            box-shadow: 0 0 30px #295cd434;
            background-color: #262626;
            color: #fff;
            font-size: 20px;
            font-family: "FuturaMedium";
            cursor: pointer;
          }
        }
        .servicesRemovableContent {
          .servicesModalButton {
            width: 514px;
            height: 70px;
            border-radius: 10px;
            margin: 20px 0;
            border: none;
            box-shadow: 0 0 30px #295cd434;
            background-color: #262626;
            color: #fff;
            font-size: 20px;
            font-family: "FuturaMedium";
            cursor: pointer;
          }
          .servicesModalSeparator {
            position: relative;
            width: 514px;
            display: flex;
            justify-content: center;
            p {
              background-color: white;
              z-index: 100;
              position: relative;
              width: calc(100% - 350px);
            }
            div {
              z-index: 99;
              left: 0;
              right: 0;
              bottom: 0;
              top: 0;
              margin: auto;
              width: 80%;
              height: 0;
              border: 1px solid grey;
              position: absolute;
            }
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1025px) {
  .servicesModalContainer {
    .servicesModal {
      .servicesContent::-webkit-scrollbar {
        display: none;
      }

      /* Hide scrollbar for IE, Edge and Firefox */
      .servicesContent {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
      }
    }
  }
}
