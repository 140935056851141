.mobileHeroContainer {
  width: 100%;
  height: calc(100vh - 61px);
  max-height: calc(926px - 61px);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: calc(100vh - 60px);
  .fullwidth {
    width: 325px;
    max-width: 95%;
    margin: 0;
  }
  .mobileHeroTitle {
    font-family: "FuturaHeavy";
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    p {
      text-align: left;
      margin: 0;
      font-size: 35px;
    }
  }
  .mobileHeroBand {
    position: absolute;
    top: 0;
    bottom: 0;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1024px;
    margin-top: 30px;
    img {
      width: 100%;
    }
  }
  .mobileHeroText {
    display: none;
    position: absolute;
    width: 100%;
    bottom: 30px;
  }
}

@media screen and (max-width: 374px) {
  .mobileHeroContainer {
    .fullwidth {
      width: 280px;
      max-width: 95%;
      margin: 0;
    }
    .mobileHeroTitle {
      p {
        font-size: 30px;
      }
    }
  }
}
