.desktopPortfolioContainer {
  width: 1060px;
  color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  .desktopPortfolioText {
    width: 100%;
    h1 {
      font-size: 60px;
      font-family: "FuturaHeavy";
      line-height: 0.5;
    }
    p {
      font-size: 30px;
      margin: 0;
    }
  }
}
