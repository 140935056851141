.tabletContactContainer {
  width: 623px;
  color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  padding-top: 137px;
  .fullwidth {
    width: 634px;
    max-width: 95%;
    margin: 0;
  }
  .tabletContactTitle {
    width: 100%;
    h1 {
      font-size: 44px;
      font-family: "FuturaHeavy";
      line-height: 0.5;
    }
  }
  .tabletContactList {
    margin-bottom: 40px;
    width: 634px;
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 20px;
  }
  .tabletContactRights {
    margin-bottom: 26px;
    text-align: center;
  }
}
