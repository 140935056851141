.carouselMultiContainer {
  position: relative;
  width: calc(100% + 150px);
  height: 500px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  // background-color: red;
  .carouselButtons {
    position: absolute;
    z-index: 100;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 500px;
    margin: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    z-index: 102;

    button {
      cursor: pointer;
      width: 40px;
      height: 100px;
      border-radius: 10px;
      background-color: #262626;
      border: none;
      display: flex;
      align-items: center;
      justify-content: center;
      box-shadow: 0 0 30px #295cd434;
      img {
        width: 20px;
        filter: invert(1);
      }
    }
  }
  .carouselMultiShades {
    background: rgb(255, 255, 255);
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 1) 5%,
      rgba(255, 255, 255, 0) 7%,
      rgba(255, 255, 255, 0) 93%,
      rgba(255, 255, 255, 1) 95%
    );
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    pointer-events: none;
    z-index: 101;
  }
  .carouselMulti {
    width: 1060px;
    display: flex;
    gap: 42.5px;
    flex-direction: row;
    z-index: 100;
  }
}
