.pregoInputContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  input {
    -webkit-appearance: none;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 30px #295cd434;
    -webkit-box-shadow: 0 0 30px #295cd434;
    border: none;
    height: 100%;
    padding: 0 20px;
    font-size: 16px;
    font-family: "FuturaMedium";
  }
  button {
    height: 100%;
    width: 75px;
    border: none;
    color: white;
    background-color: #262626;
    border-radius: 10px;
    box-shadow: 0 0 30px #295cd434;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    img {
      margin-left: 5px;
      width: 30px;
      -webkit-filter: invert(100%); /* safari 6.0 - 9.0 */
      filter: invert(100%);
    }
  }
}
