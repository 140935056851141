.tabletHeroContainer {
  width: 100%;
  height: calc(100vh - 113px);
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  // height: calc(100vh - 60px);
  .fullwidth {
    width: 634px;
    max-width: 95%;
    margin: 0;
  }
  .tabletHeroTitle {
    font-family: "FuturaHeavy";
    margin-top: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    p {
      text-align: left;
      margin: 0;
      font-size: 44px;
    }
  }
  .tabletHeroBand {
    margin: 130px;
    transform: scale(1.4);
    width: 1024px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: auto;
    }
  }
  .tabletHeroText {
    bottom: 30px;
    position: absolute;
    width: 100%;
    font-size: 27px;
  }
}

// Screen for Ipad Pro
@media screen and (width: 1024px) {
  .tabletHeroContainer {
    width: 100%;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: calc(100vh - 60px);
    .fullwidth {
      width: 634px;
      max-width: 95%;
      margin: 0;
    }
    .tabletHeroTitle {
      font-family: "FuturaHeavy";
      margin-top: 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: left;
      p {
        text-align: left;
        margin: 0;
        font-size: 54px;
      }
    }
    // .tabletHeroBand {
    //   height: 950px;
    //   width: 950px;
    //   margin: -10px 0px 0px -15%;
    //   display: flex;
    // }
    // .tabletHeroText {
    //   width: 100%;
    //   font-size: 32px;
    //   margin-bottom: 150px;
    // }
  }
}
