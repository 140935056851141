.desktopServicesContainer {
  width: 1060px;
  padding: 200px 0;
  color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  .desktopServicesText {
    width: 100%;
    h1 {
      font-size: 60px;
      font-family: "FuturaHeavy";
      line-height: 0.5;
    }
    p {
      font-size: 30px;
      margin: 0;
    }
  }
  .desktopServicesGrid {
    width: 100%;
    display: grid;
    grid-template-columns: 510px 510px;
    grid-template-rows: 420px;
    column-gap: 40px;
    row-gap: 40px;
  }
}
