.portfolioCardTabletContainer {
  width: 634px;
  height: 320px;
  margin: 30px calc((100vw - 634px) / 8);
  position: relative;
  .portfolioCardTabletText {
    margin: 0 40px;
    padding-top: 40px;
    display: flex;
    justify-content: left;
    align-items: center;
    img {
      width: 70px;
      margin-right: 10px;
    }
    h1 {
      text-align: left;
    }
  }
  p {
    font-size: 22px;
    margin-left: 40px;
    width: calc(100% - 300px);
    text-align: left;
  }
  .portfolioCardTabletMockup {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 150px;
    img {
      width: 100%;
    }
  }
  .portfolioCardLink {
    z-index: 500;
    color: #262626;
    text-decoration: none;
  }
}
