.simpleBoxContainer {
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  height: 75px;
  width: 325px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 30px #295cd434;
  margin: 15px 0;
  img {
    margin: 0 20px;
    width: 30px;
  }
  p {
    margin: 0;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    margin-right: 20px;
  }
}
