.pregoPredefinedContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 75px;
  width: 325px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 30px #295cd434;
  margin: 10px 0;
  cursor: pointer;
}
