.tabletNavbarContainer {
  z-index: 1000;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 112px;
  border-bottom: 1px solid #dededed0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 27px;
  p {
    margin: 0 40px;
  }
  .navHamburgerContainer {
    margin: 0 40px;
    img {
      width: 30px;
    }
  }
}
