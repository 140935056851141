.desktopAboutContainer {
  width: 1060px;
  color: #262626;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  text-align: left;
  .desktopAboutText {
    width: 50%;
    h1 {
      font-size: 60px;
      font-family: "FuturaHeavy";
      line-height: 0.5;
    }
    p {
      font-size: 30px;
      margin: 0;
    }
  }
  .desktopAboutGrid {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: center;
    .desktopAboutCol {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: -30px;
      div {
        margin: 10px;
      }
    }
  }
}
