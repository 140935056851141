.hamburgerContainer {
  width: 100%;
  height: 400px;
  position: fixed;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(10px);
  border-bottom: 1px solid #dededed0;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  pointer-events: all;
  li {
    list-style: none;
    color: #262626;
    text-transform: uppercase;
    margin: 10px;
    padding: 10px;
  }
}
