.carouselContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  .carousel {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: absolute;
    left: 0;
  }
  .carouselIndicator {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    div {
      width: 18px;
      height: 6px;
      background-color: #262626;
      opacity: 0.5;
      border-radius: 1000px;
      margin: 0 6px;
    }
  }
}
