.mobilePortfolioContainer {
  width: 100%;
  overflow: visible;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
  .fullwidth {
    width: 325px;
    max-width: 95%;
    margin: 0;
  }
  .mobilePortfolioTitle {
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    font-family: "FuturaHeavy";
    p {
      text-align: left;
      margin: 0;
      font-size: 35px;
      line-height: 1;
    }
  }
  .mobilePortfolioSubtitle {
    margin-top: 5px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    margin-bottom: 20px;
    p {
      text-align: left;
      margin: 0;
      font-size: 20px;
    }
  }
}

@media screen and (max-width: 374px) {
  .mobilePortfolioContainer {
    .fullwidth {
      width: 280px;
      max-width: 95%;
      margin: 0;
    }
    .mobilePortfolioTitle {
      p {
        font-size: 30px;
      }
    }
  }
}
