.carouselBleedingContainer {
  position: relative;
  width: 100%;
  overflow: hidden;
  .carousel {
    display: flex;
    flex-direction: row;
    overflow: hidden;
    position: absolute;
    left: 0;
  }
}
