.desktopPregoContainer {
  width: 1060px;
  color: #262626;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-top: 200px;
  .desktopPregoText {
    width: 100%;
    h1 {
      font-size: 60px;
      font-family: "FuturaHeavy";
      line-height: 0.5;
    }
  }
  .desktopPrego {
    display: grid;
    grid-template-columns: 515px 515px;
    column-gap: 30px;
    width: 100%;
    div {
      width: 100%;
    }
  }
}
