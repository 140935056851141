.portfolioCardContainer {
  width: 100vw;
  height: 100%;
  position: relative;
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .portfolioCard {
    position: relative;
    max-width: 90vw;
    width: 325px;
    height: 460px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 30px #295cd434;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .portfolioCardShade {
      width: 100%;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgb(39, 77, 167);
      background: linear-gradient(
        0deg,
        rgba(39, 77, 167, 0.3) 0%,
        rgba(39, 77, 167, 0) 100%
      );
    }
    .portfolioCardShade {
      width: 100%;
      height: 100px;
      position: absolute;
      left: 0;
      bottom: 0;
      background: rgb(39, 77, 167);
      background: linear-gradient(
        0deg,
        rgba(39, 77, 167, 0.3) 0%,
        rgba(39, 77, 167, 0) 100%
      );
    }
    .portfolioCardMockup {
      margin: 0px 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 270px;
      }
    }


    .portfolioCardTop {
      display: flex;
      justify-content: space-between;
      flex-direction: row;
      align-items: center;
      margin: 20px;

      img {
        width: 40px;
        height: 24px;
        size: 12;
      }
      h1 {
        font-size: 17px;
        font-family: "FuturaHeavy";
        line-height: 1.5;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    p {
      text-align: left;
      margin: 0;
      margin: 10px 20px;
      margin-bottom: 30px;
      font-size: 20px;
    }
  }
  .portfolioCardLink {
    z-index: 500;
    color: #262626;
    text-decoration: none;
  }
}
