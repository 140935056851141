.mobileNavbarContainer {
  z-index: 1000;
  background-color: white;
  // backdrop-filter: blur(10px);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px;
  border-bottom: 1px solid #dededed0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  p {
    margin: 0 20px;
  }
  .navHamburgerContainer {
    margin: 0 20px;
    img {
      width: 25px;
    }
  }
}
