.desktopHeroContainer {
  background-color: #262626;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  .desktopHero {
    width: 1060px;
    height: 100vh;
    color: white;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    .desktopHeroText {
      width: 50%;
      div {
        margin: 100px 0;
        h1 {
          font-size: 60px;
          font-family: "FuturaHeavy";
          line-height: 0.5;
        }
        p {
          font-size: 30px;
          margin: 0;
        }
      }
    }
    .desktopHeroImg {
      width: 50%;
      img {
        margin-left: 100px;
        width: 400px;
      }
    }
  }
}

@media screen and (width: 1920px) {
  .desktopHeroContainer {
    background-color: #262626;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .desktopHero {
      width: 1060px;
      height: 100vh;
      color: white;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      text-align: left;
      .desktopHeroText {
        width: 50%;
        div {
          margin: 100px 0;
          h1 {
            font-size: 60px;
            font-family: "FuturaHeavy";
            line-height: 0.5;
          }
          p {
            font-size: 30px;
            margin: 0;
          }
        }
      }
      .desktopHeroImg {
        margin-top: 50%;
        height: 1013px;
        width: 465px;
      }
    }
  }
}
