.servicesCard {
  position: relative;
  max-width: 90vw;
  width: 325px;
  height: 420px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 30px #295cd434;
  display: flex;
  flex-direction: column;
  margin: 20px calc((100vw - 325px) / 2);
  .servicesTitle {
    width: calc(100% - 40px);
    padding: 0 20px;
    height: 80px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    h1 {
      font-size: 23px;
      font-family: "FuturaHeavy";
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 80%;
      margin: 0;
      text-align: left;
    }
    img {
      width: 35px;
    }
  }
  .servicesImg {
    width: 100%;
    height: 200px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .servicesDescription {
    display: flex;
    width: 100%;
    height: 140px;
    justify-content: center;
    align-items: center;
    p {
      font-size: 18px;
      text-align: left;
      padding: 0 20px;
    }
  }
}

@media screen and (min-width: 541px) {
  .servicesCard {
    position: relative;
    max-width: 90vw;
    width: 634px;
    height: 420px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 30px #295cd434;
    display: flex;
    flex-direction: column;
    margin: 25px calc((100vw - 634px) / 8);
    position: relative;
    .servicesTitle {
      width: calc(100% - 120px);
      padding: 0 60px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: 23px;
        font-family: "FuturaHeavy";
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        margin: 0;
        text-align: left;
      }
      img {
        width: 35px;
      }
    }
    .servicesImg {
      width: 100%;
      height: 200px;
      img {
        width: 100%;
      }
    }
    .servicesDescription {
      display: flex;
      width: 100%;
      height: 140px;
      justify-content: center;
      align-items: center;
      p {
        font-size: 20px;
        text-align: left;
        padding: 0 60px;
      }
    }
  }
}
@media screen and (min-width: 1025px) {
  .servicesCard {
    cursor: pointer;
    position: relative;
    max-width: 90vw;
    width: 510px;
    height: 420px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 30px #295cd434;
    display: flex;
    flex-direction: column;
    margin: 30px 0;
    position: relative;
    .servicesTitle {
      width: calc(100% - 100px);
      padding: 0 50px;
      height: 80px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      h1 {
        font-size: 23px;
        font-family: "FuturaHeavy";
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 80%;
        margin: 0;
        text-align: left;
      }
      img {
        width: 35px;
      }
    }
    .servicesImg {
      width: 100%;
      height: 200px;
    }
    .servicesDescription {
      display: flex;
      width: 100%;
      height: 140px;
      justify-content: center;
      align-items: center;
      p {
        font-size: 20px;
        text-align: left;
        padding: 0 50px;
      }
    }
  }
}
