@media screen and (min-width: 1025px) {
  .pregoContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: 100%;

    .pregoQuestion {
      font-size: 30px;
      text-align: left;
      margin: 0;
    }
    .pregoAnswers {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-top: 20px;
      column-gap: 20px;
      div {
        width: 100%;
        input {
          width: 385px;
        }
      }
    }
  }
}

@media screen and (min-width: 376px) and (max-width: 1024px) {
  .pregoContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: 100%;

    .pregoQuestion {
      font-size: 30px;
      text-align: left;
      margin: 0;
    }
    .pregoAnswers {
      display: flex;
      flex-direction: column;
      margin-top: 40px;
      div {
        width: 100%;
        input {
          width: 445px;
          padding: 0 40px;
        }
      }
    }
  }
}

@media screen and (max-width: 540px) {
  .pregoContainer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: left;
    width: 325px;

    .pregoQuestion {
      font-size: 20px;
      text-align: left;
      margin: 0;
      margin-top: -15px;
      width: 275px;
    }
    .pregoAnswers {
      margin-top: 30px;
    }
  }
}
